import BaseSvg, { Props } from '../BaseSvg'

const FloorPlan: React.FC<Props> = (props) => (
  <BaseSvg viewBox="0 0 16 16" {...props}>
    <path
      clipRule="evenodd"
      d="M3.1237 1.8335H1.45703V12.6668C1.45703 14.0475 2.57632 15.1668 3.95703 15.1668H12.2904C13.6711 15.1668 14.7904 14.0475 14.7904 12.6668V1.8335H13.1237V2.66683H3.1237V1.8335ZM3.1237 4.3335V10.3091C3.38435 10.217 3.66483 10.1668 3.95703 10.1668H4.79036V4.3335H3.1237ZM6.04036 11.8335V4.3335H7.29036V6.00016H8.95703V4.3335H10.207V7.66683H8.1237V9.3335H10.207V11.8335H12.2904C12.7506 11.8335 13.1237 12.2066 13.1237 12.6668C13.1237 13.1271 12.7506 13.5002 12.2904 13.5002H3.95703C3.49679 13.5002 3.1237 13.1271 3.1237 12.6668C3.1237 12.2066 3.49679 11.8335 3.95703 11.8335H6.04036ZM11.457 10.1668H12.2904C12.5826 10.1668 12.863 10.217 13.1237 10.3091V4.3335H11.457V10.1668Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </BaseSvg>
)

export default FloorPlan
