import BaseSvg, { Props } from '../BaseSvg'

const ArrowLeft: React.FC<Props> = (props) => (
  <BaseSvg viewBox="0 0 16 16" {...props}>
    <path
      clipRule="evenodd"
      d="M8.20712 0.292969L0.500017 8.00008L8.20712 15.7072L9.62134 14.293L4.33023 8.99997L16.5 9.00008V7.00008L4.33023 6.99997L9.62134 1.70718L8.20712 0.292969Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </BaseSvg>
)

export default ArrowLeft
