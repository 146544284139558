import BaseSvg, { Props } from '../BaseSvg'

const Heart: React.FC<Props> = (props) => (
  <BaseSvg height="19" viewBox="0 0 20 19" width="20 " {...props}>
    <path
      clipRule="evenodd"
      d="M9.99999 1.83854C11.9262 0.234375 13.9314 -0.166666 16.0156 0.635416C18.6724 1.65786 19.625 4.18653 19.625 6.65103C19.625 10.1949 16.4166 14.2053 9.99999 18.6823C3.58333 14.2053 0.375 10.1949 0.375 6.65103C0.375 4.37928 1.29611 1.69567 3.98437 0.635416C6.01804 -0.166666 8.02324 0.234375 9.99999 1.83854ZM15.1514 2.88111C13.9848 2.43218 12.9474 2.59952 11.7636 3.5084L11.5399 3.68753L10.0198 4.95349L8.48371 3.70696C7.15226 2.62646 6.05227 2.40646 4.8672 2.87385C3.51862 3.40574 2.78123 4.82164 2.78123 6.65103C2.78123 8.93677 5.03399 11.9757 9.75785 15.5361L9.99997 15.7166L10.2421 15.5361C14.8383 12.0719 17.0951 9.10142 17.2138 6.83801L17.2187 6.65103C17.2187 4.72415 16.5148 3.40582 15.1514 2.88111Z"
      fillRule="evenodd"
    />
  </BaseSvg>
)

export default Heart
