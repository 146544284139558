import BaseSvg, { Props } from '../BaseSvg'

const Tv: React.FC<Props> = (props) => (
  <BaseSvg viewBox="0 0 16 16" {...props}>
    <path
      clipRule="evenodd"
      d="M16 17H0V4L4.422 3.99995L3.13397 1.76795L4.86603 0.767952L6.732 3.99995H9.267L11.134 0.767952L12.866 1.76795L11.577 3.99995L16 4V17ZM14 6H2V14H14V6Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </BaseSvg>
)

export default Tv
