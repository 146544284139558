import BaseSvg, { Props } from '../BaseSvg'

const Trash: React.FC<Props> = (props) => (
  <BaseSvg viewBox="0 0 13 16" {...props}>
    <path
      d="M9.00727 0.714326C9.04841 0.800922 9.07143 0.897793 9.07143 1.00004V2.04766L12.5 2.04766V3.76195H0.5V2.04766L3.92857 2.04766V1.00004C3.92857 0.897793 3.95159 0.800922 3.99273 0.714326C4.09972 0.489093 4.3293 0.333374 4.59524 0.333374H8.40476C8.6707 0.333374 8.90028 0.489093 9.00727 0.714326Z"
      fill="currentColor"
    />
    <path
      d="M2.02381 4.61909V11.8572C2.02381 12.2254 2.32229 12.5239 2.69048 12.5239H10.3095C10.6777 12.5239 10.9762 12.2254 10.9762 11.8572V4.61909H12.3095V11.8572C12.3095 12.9618 11.4141 13.8572 10.3095 13.8572H2.69048C1.58591 13.8572 0.690476 12.9618 0.690476 11.8572V4.61909H2.02381Z"
      fill="currentColor"
    />
    <path d="M3.92857 4.61909H5.64286V10.6191H3.92857V4.61909Z" fill="currentColor" />
    <path d="M7.35714 4.61909H9.07143V10.6191H7.35714V4.61909Z" fill="currentColor" />
  </BaseSvg>
)

export default Trash
