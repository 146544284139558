import BaseSvg, { Props } from '../BaseSvg'

const Bathroom: React.FC<Props> = (props) => (
  <BaseSvg viewBox="0 0 16 16" {...props}>
    <path
      clipRule="evenodd"
      d="M12 0C14.2091 0 16 1.79086 16 4V11.5C16 12.8958 15.183 14.1007 14.001 14.6627L14 15C14 15.5523 13.5523 16 13 16C12.4477 16 12 15.5523 12 15H4C4 15.5523 3.55228 16 3 16C2.44772 16 2 15.5523 2 15L1.99997 14.6632C0.817511 14.1014 0 12.8962 0 11.5V9C0 8.44771 0.447715 8 1 8H14V4C14 2.94564 13.1841 2.08183 12.1493 2.00549L12 2L10.4648 1.99988C10.8052 2.58825 11 3.27138 11 4V6H3V4C3 1.79086 4.79086 0 7 0H12ZM14 10H2V11.5C2 12.2797 2.59489 12.9204 3.35554 12.9931L3.5 13H12.5C13.2797 13 13.9204 12.4051 13.9931 11.6445L14 11.5V10ZM7 2C5.94564 2 5.08183 2.81588 5.00549 3.85074L5 4H9C9 2.94564 8.18412 2.08183 7.14926 2.00549L7 2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </BaseSvg>
)

export default Bathroom
