import cn from 'classnames'

import { dividerRecipes } from './styles.css'

export type Props = {
  className?: string
  colorVariant?: 'greyscale100' | 'greyscale500' | 'white' | 'greyscale300'
  fullWidth?: boolean
  noVerticalMargins?: boolean
}
export const Divider: React.FC<Props> = ({
  className,
  colorVariant = 'greyscale100',
  fullWidth = false,
  noVerticalMargins = false,
}) => <hr className={cn(dividerRecipes({ color: colorVariant, fullWidth, noVerticalMargins }), className)} />
