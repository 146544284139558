import BaseSvg, { Props } from '../BaseSvg'

const Avatar: React.FC<Props> = (props) => {
  return (
    <BaseSvg viewBox="0 0 24 24" {...props}>
      <circle cx="12" cy="12" fill="none" r="10" stroke="currentColor" />
      <path
        d="M12 0C5.376 0 0 5.376 0 12s5.376 12 12 12 12-5.376 12-12S18.624 0 12 0zm7.632 17.796C17.916 15.708 13.752 15 12 15c-1.752 0-5.916.708-7.632 2.796A9.54 9.54 0 0 1 2.4 12c0-5.292 4.308-9.6 9.6-9.6s9.6 4.308 9.6 9.6a9.54 9.54 0 0 1-1.968 5.796zM12 4.8A4.19 4.19 0 0 0 7.8 9a4.19 4.19 0 0 0 4.2 4.2A4.19 4.19 0 0 0 16.2 9 4.19 4.19 0 0 0 12 4.8z"
        fill="currentColor"
      />
    </BaseSvg>
  )
}

export default Avatar
