import BaseSvg, { Props } from '../BaseSvg'

const Measure: React.FC<Props> = (props) => (
  <BaseSvg viewBox="0 0 16 16" {...props}>
    <path
      clipRule="evenodd"
      d="M4.5 0C6.98528 0 9 2.01472 9 4.5C9 5.4253 8.72073 6.28537 8.24188 7.00052L16 7V16H3C1.34315 16 0 14.6569 0 13V4L0.0274316 4.0003C0.276019 1.75018 2.18362 0 4.5 0ZM14 9H2V13C2 13.5128 2.38604 13.9355 2.88338 13.9933L3 14H5V11H7V14H10V11H12V14H14V9ZM4.5 2C3.11929 2 2 3.11929 2 4.5C2 5.88071 3.11929 7 4.5 7C5.88071 7 7 5.88071 7 4.5C7 3.11929 5.88071 2 4.5 2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </BaseSvg>
)

export default Measure
