import React from 'react'

import { SvgProps } from '..'
import BaseSvg from '../BaseSvg'

const Draft: React.FC<SvgProps> = (props) => {
  return (
    <BaseSvg viewBox="0 0 34 34" {...props}>
      <path
        clipRule="evenodd"
        d="M6.58334 0.333252C5.43274 0.333252 4.5 1.26599 4.5 2.41659V4.49992H2.41667C1.26608 4.49992 0.333336 5.43266 0.333336 6.58325V31.5833C0.333336 32.7338 1.26608 33.6666 2.41667 33.6666H23.25C24.4006 33.6666 25.3333 32.7338 25.3333 31.5833H31.5833C32.7339 31.5833 33.6667 30.6505 33.6667 29.4999V8.66659C33.6667 4.06421 29.9357 0.333252 25.3333 0.333252H6.58334ZM8.66667 4.49992V27.4166H29.5V14.9166H19.0833V4.49992H8.66667ZM29.5 8.66659V10.7499H23.25V4.49992H25.3333C27.6345 4.49992 29.5 6.3654 29.5 8.66659Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </BaseSvg>
  )
}

export default Draft
