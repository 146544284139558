import BaseSvg, { Props } from '../BaseSvg'

const Info: React.FC<Props> = (props) => (
  <BaseSvg viewBox="0 0 28 28" {...props}>
    <path
      clipRule="evenodd"
      d="M13.9998 0.666667C21.3636 0.666667 27.3332 6.6362 27.3332 14C27.3332 21.3638 21.3636 27.3333 13.9998 27.3333C6.63604 27.3333 0.666504 21.3638 0.666504 14C0.666504 6.6362 6.63604 0.666667 13.9998 0.666667ZM13.9998 4C8.47699 4 3.99984 8.47715 3.99984 14C3.99984 19.5228 8.47699 24 13.9998 24C19.5227 24 23.9998 19.5228 23.9998 14C23.9998 8.47715 19.5227 4 13.9998 4ZM15.6665 13.1667V21.5H12.3332V13.1667H15.6665ZM13.9998 6.5C15.3805 6.5 16.4998 7.61929 16.4998 9C16.4998 10.3807 15.3805 11.5 13.9998 11.5C12.6191 11.5 11.4998 10.3807 11.4998 9C11.4998 7.61929 12.6191 6.5 13.9998 6.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </BaseSvg>
)

export default Info
