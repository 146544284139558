import BaseSvg, { Props } from '../BaseSvg'

const Loader: React.FC<Props> = (props) => (
  <BaseSvg viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0_621_6464)">
      <path
        d="M13.3335 8.00008L15.3335 8.00008L12.6668 5.33341L10.0002 8.00008L12.0002 8.00008C12.0002 10.2067 10.2068 12.0001 8.00016 12.0001C7.32683 12.0001 6.68683 11.8334 6.1335 11.5334L5.16016 12.5067C5.98016 13.0267 6.9535 13.3334 8.00016 13.3334C10.9468 13.3334 13.3335 10.9467 13.3335 8.00008ZM4.00016 8.00008C4.00016 5.79341 5.7935 4.00008 8.00016 4.00008C8.6735 4.00008 9.3135 4.16675 9.86683 4.46675L10.8402 3.49341C10.0202 2.97341 9.04683 2.66675 8.00016 2.66675C5.0535 2.66675 2.66683 5.05341 2.66683 8.00008L0.66683 8.00008L3.3335 10.6667L6.00016 8.00008L4.00016 8.00008Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_621_6464">
        <rect fill="white" height="16" transform="translate(16) rotate(90)" width="16" />
      </clipPath>
    </defs>
  </BaseSvg>
)

export default Loader
