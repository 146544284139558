import BaseSvg, { Props } from '../BaseSvg'

const Magnify: React.FC<Props> = (props) => (
  <BaseSvg viewBox="0 0 24 24" {...props}>
    <path
      clipRule="evenodd"
      d="M10.6865 2.48529C14.9316 2.48529 18.3729 5.92663 18.3729 10.1717C18.3729 11.8671 17.824 13.4344 16.8942 14.7053L21.5147 19.3277L19.8424 21L15.22 16.3795C13.9491 17.3093 12.3819 17.8582 10.6865 17.8582C6.44134 17.8582 3 14.4169 3 10.1717C3 5.92663 6.44134 2.48529 10.6865 2.48529ZM10.6865 4.85035C7.74753 4.85035 5.36506 7.23282 5.36506 10.1717C5.36506 13.1107 7.74753 15.4931 10.6865 15.4931C13.6254 15.4931 16.0078 13.1107 16.0078 10.1717C16.0078 7.23282 13.6254 4.85035 10.6865 4.85035Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </BaseSvg>
)

export default Magnify
