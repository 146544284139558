import BaseSvg, { Props } from '../BaseSvg'

const Plus: React.FC<Props> = (props) => (
  <BaseSvg viewBox="0 0 34 34" {...props}>
    <path
      clipRule="evenodd"
      d="M19.3496 0.35434V14.9377H33.9329V19.1043H19.3496V33.6877H15.1829V19.1043H0.599609V14.9377H15.1829V0.35434H19.3496Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </BaseSvg>
)

export default Plus
