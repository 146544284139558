import HouseCheck from '@/components/Icon/HouseCheck'

import Alert from './Alert'
import ArrowLeft from './ArrowLeft'
import Avatar from './Avatar'
import Bathroom from './Bathroom'
import Bedroom from './Bedroom'
import Bell from './Bell'
import BrokenHeart from './BrokenHeart'
import Close from './Close'
import Draft from './Draft'
import Edit from './Edit'
import Euro from './Euro'
import FloorPlan from './FloorPlan'
import Heart from './Heart'
import HouseRent from './HouseRent'
import HouseSmall from './HouseSmall'
import Info from './Info'
import Loader from './Loader'
import MagicIcon from './MagicIcon'
import Magnify from './Magnify'
import Measure from './Measure'
import Plus from './Plus'
import Trash from './Trash'
import Tune from './Tune'
import Tv from './Tv'
import User from './User'

export type SvgProps = React.SVGProps<SVGSVGElement>

const ALL_ICONS = [
  'Alert',
  'ArrowLeft',
  'Avatar',
  'Bathroom',
  'Bedroom',
  'Bell',
  'BrokenHeart',
  'Close',
  'Draft',
  'Edit',
  'Euro',
  'FloorPlan',
  'Heart',
  'HouseRent',
  'HouseCheck',
  'Info',
  'Loader',
  'Magnify',
  'Measure',
  'Plus',
  'HouseSmall',
  'Trash',
  'Tv',
  'User',
  'Tune',
  'MagicIcon',
] as const
export type IconKey = typeof ALL_ICONS[number]

type IconProps = {
  [x in IconKey]: React.FC<SvgProps>
}
const Icon: IconProps = {
  Alert,
  ArrowLeft,
  Avatar,
  Bathroom,
  Bedroom,
  Bell,
  BrokenHeart,
  Close,
  Draft,
  Edit,
  Euro,
  FloorPlan,
  Heart,
  HouseCheck,
  HouseRent,
  HouseSmall,
  Info,
  Loader,
  MagicIcon,
  Magnify,
  Measure,
  Plus,
  Trash,
  Tune,
  Tv,
  User,
}

export default Icon
