import { SvgProps } from '..'
import BaseSvg from '../BaseSvg'

const Bell: React.FC<SvgProps> = (props) => {
  return (
    <BaseSvg className={props.className} {...props}>
      <path
        clipRule="evenodd"
        d="M0.000348197 12H2V7C2 4.34058 3.7302 2.08528 6.12636 1.29834C6.4102 0.540274 7.14201 0 8 0C8.8577 0 9.58929 0.539901 9.87348 1.29839C12.2693 2.08465 14 4.34022 14 7V12H15.9997L16 14L10.829 14.0009C10.6594 14.4803 10.3713 14.9038 10 15.2361C9.46924 15.7111 8.76835 16 8 16C7.23165 16 6.53076 15.7111 6 15.2361C5.62869 14.9038 5.34065 14.4803 5.17102 14.0009L0 14L0.000348197 12ZM4.0049 6.80036L4 7V12H12V7C12 4.8578 10.316 3.10892 8.19964 3.0049L8 3C5.8578 3 4.10892 4.68397 4.0049 6.80036Z"
        fillRule="evenodd"
      />
    </BaseSvg>
  )
}

export default Bell
