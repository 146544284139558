import BaseSvg, { Props } from '../BaseSvg'

const Tune: React.FC<Props> = (props) => (
  <BaseSvg viewBox="0 0 18 16" {...props}>
    <g clipPath="url(#clip0_1756_3360)">
      <path
        d="M2 11.3333V12.6667H6V11.3333H2ZM2 3.33333V4.66667H8.66667V3.33333H2ZM8.66667 14V12.6667H14V11.3333H8.66667V10H7.33333V14H8.66667ZM4.66667 6V7.33333H2V8.66667H4.66667V10H6V6H4.66667ZM14 8.66667V7.33333H7.33333V8.66667H14ZM10 6H11.3333V4.66667H14V3.33333H11.3333V2H10V6Z"
        fill="#1D1D1B"
      />
    </g>
    <defs>
      <clipPath id="clip0_1756_3360">
        <rect fill="white" height="16" width="16" />
      </clipPath>
    </defs>
  </BaseSvg>
)

export default Tune
