import BaseSvg, { Props } from '../BaseSvg'

const Bedroom: React.FC<Props> = (props) => (
  <BaseSvg viewBox="0 0 16 16" {...props}>
    <path
      clipRule="evenodd"
      d="M13 0C14.1046 0 15 0.89543 15 2V8C15.5523 8 16 8.44771 16 9V15H13C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15H5C5 15.5523 4.55228 16 4 16C3.44772 16 3 15.5523 3 15H0V9C0 8.44771 0.447715 8 1 8V2C1 0.89543 1.89543 0 3 0H13ZM14 10H2V13H14V10ZM13 2H3V8L4.0501 8.00043C4.01725 7.83874 4 7.67139 4 7.5C4 6.11929 5.11929 5 6.5 5H9.5C10.8807 5 12 6.11929 12 7.5C12 7.67139 11.9828 7.83874 11.9499 8.00043L13 8V2ZM9.5 7H6.5C6.22386 7 6 7.22386 6 7.5C6 7.74546 6.17688 7.94961 6.41012 7.99194L6.5 8H9.5C9.77614 8 10 7.77614 10 7.5C10 7.25454 9.82312 7.05039 9.58988 7.00806L9.5 7Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </BaseSvg>
)

export default Bedroom
