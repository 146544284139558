import BaseSvg, { Props } from '../BaseSvg'

const HouseRent: React.FC<Props> = (props) => (
  <BaseSvg viewBox="0 0 34 34" {...props}>
    <path
      clipRule="evenodd"
      d="M16.9997 0.333344L33.6663 9.5926V33.6667H0.333008V9.5926L16.9997 0.333344ZM16.9997 5.10001L4.49967 12.0417V29.5H18.1038L16.4851 23.4604L11.5018 24.7966L9.88423 18.7596L23.9706 14.9852L25.5883 21.0222L20.5101 22.3813L22.4163 29.5H29.4997V12.0438L16.9997 5.10001Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </BaseSvg>
)

export default HouseRent
