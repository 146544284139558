import BaseSvg, { Props } from '../BaseSvg'

const HouseCheck: React.FC<Props> = (props) => (
  <BaseSvg {...props}>
    <path
      clipRule="evenodd"
      d="M7.00001 0.333328L13.6667 4.03703V13.6667H0.333344V4.03703L7.00001 0.333328ZM7.00001 2.23999L2.00001 5.01666V12H12V5.01749L7.00001 2.23999ZM8.91076 5.57741L10.0893 6.75592L6.16668 10.6785L3.91076 8.42258L5.08927 7.24407L6.16668 8.32083L8.91076 5.57741Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </BaseSvg>
)

export default HouseCheck
