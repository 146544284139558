import React from 'react'

const MagicIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clip-path="url(#clip0_21803_22152)">
        <path
          d="M12.964 0H1.036C0.462 0 0 0.462 0 1.036V12.964C0 13.5333 0.462 14 1.036 14H12.964C13.5333 14 14 13.538 14 12.964V1.036C14 0.466667 13.538 0 12.964 0ZM9.80467 8.25067L7.48067 9.16533C7.392 9.198 7.322 9.268 7.28933 9.35667L6.37467 11.6807C6.26267 11.9653 5.85667 11.9653 5.74467 11.6807L4.83 9.35667C4.79733 9.268 4.72733 9.198 4.63867 9.16533L2.31467 8.25067C2.03 8.13867 2.03 7.73267 2.31467 7.62067L4.63867 6.706C4.72733 6.67333 4.79733 6.60333 4.83 6.51467L5.74467 4.19067C5.85667 3.906 6.26267 3.906 6.37467 4.19067L7.28933 6.51467C7.322 6.60333 7.392 6.67333 7.48067 6.706L9.80467 7.62067C10.0893 7.73267 10.0893 8.13867 9.80467 8.25067ZM11.7787 4.368L10.5467 4.85333C10.5 4.872 10.4627 4.90933 10.444 4.956L9.95867 6.188C9.898 6.342 9.68333 6.342 9.62267 6.188L9.13733 4.956C9.11867 4.90933 9.08133 4.872 9.03467 4.85333L7.80267 4.368C7.64867 4.30733 7.64867 4.09267 7.80267 4.032L9.03467 3.54667C9.08133 3.528 9.11867 3.49067 9.13733 3.444L9.62267 2.212C9.68333 2.058 9.898 2.058 9.95867 2.212L10.444 3.444C10.4627 3.49067 10.5 3.528 10.5467 3.54667L11.7787 4.032C11.9327 4.09267 11.9327 4.30733 11.7787 4.368Z"
          fill="#FF991F"
        />
        <path
          d="M9.80506 7.62067L7.48106 6.70601C7.3924 6.67334 7.3224 6.60334 7.28973 6.51467L6.37506 4.19067C6.26306 3.90601 5.85706 3.90601 5.74506 4.19067L4.8304 6.51467C4.79773 6.60334 4.72773 6.67334 4.63906 6.70601L2.31506 7.62067C2.0304 7.73267 2.0304 8.13867 2.31506 8.25067L4.63906 9.16534C4.72773 9.19801 4.79773 9.26801 4.8304 9.35667L5.74506 11.6807C5.85706 11.9653 6.26306 11.9653 6.37506 11.6807L7.28973 9.35667C7.3224 9.26801 7.3924 9.19801 7.48106 9.16534L9.80506 8.25067C10.0897 8.13867 10.0897 7.73267 9.80506 7.62067Z"
          fill="#1D1D1B"
        />
        <path
          d="M11.7829 4.0367L10.5509 3.55136C10.5042 3.5327 10.4669 3.49536 10.4482 3.4487L9.96291 2.2167C9.90224 2.0627 9.68757 2.0627 9.62691 2.2167L9.14157 3.4487C9.12291 3.49536 9.08557 3.5327 9.03891 3.55136L7.80691 4.0367C7.65291 4.09736 7.65291 4.31203 7.80691 4.3727L9.03891 4.85803C9.08557 4.8767 9.12291 4.91403 9.14157 4.9607L9.62691 6.1927C9.68757 6.3467 9.90224 6.3467 9.96291 6.1927L10.4482 4.9607C10.4669 4.91403 10.5042 4.8767 10.5509 4.85803L11.7829 4.3727C11.9369 4.31203 11.9369 4.09736 11.7829 4.0367Z"
          fill="#1D1D1B"
        />
      </g>
      <defs>
        <clipPath id="clip0_21803_22152">
          <rect fill="white" height="14" width="14" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MagicIcon
