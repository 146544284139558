import BaseSvg, { Props } from '../BaseSvg'

const Alert: React.FC<Props> = (props) => (
  <BaseSvg
    viewBox="0 0 18 16"
    {...props}
    aria-labelledby={`${props['aria-labelledby'] ? props['aria-labelledby'] : ''} required-icon`}
  >
    <title id="required-icon">Required field</title>
    <path
      d="M0.75 14.75H17.25L9 0.5L0.75 14.75ZM9.75 12.5H8.25V11H9.75V12.5ZM9.75 9.5H8.25V6.5H9.75V9.5Z"
      fill="currentColor"
    />
  </BaseSvg>
)

export default Alert
