import BaseSvg, { Props } from '../BaseSvg'

const User: React.FC<Props> = (props) => {
  return (
    <BaseSvg viewBox="0 0 20 20" {...props}>
      <path
        clipRule="evenodd"
        d="M10 0C13.4518 0 16.25 2.79822 16.25 6.25C16.25 7.54037 15.859 8.73942 15.1889 9.73508L15 10C17.7614 10 20 12.2386 20 15V20H0V15C0 12.2386 2.23858 10 5 10C4.21538 8.95667 3.75 7.65768 3.75 6.25C3.75 2.79822 6.54822 0 10 0ZM15 12.5H5C3.68205 12.5 2.60229 13.5198 2.50686 14.8134L2.5 15V17.5H17.5V15C17.5 13.682 16.4802 12.6023 15.1866 12.5069L15 12.5ZM10 2.5C7.92893 2.5 6.25 4.17893 6.25 6.25C6.25 8.32107 7.92893 10 10 10C12.0711 10 13.75 8.32107 13.75 6.25C13.75 4.17893 12.0711 2.5 10 2.5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </BaseSvg>
  )
}

export default User
