import BaseSvg, { Props } from '../BaseSvg'

const Euro: React.FC<Props> = (props) => (
  <BaseSvg viewBox="0 0 18 18" {...props}>
    <path
      clipRule="evenodd"
      d="M11.8333 2C14.0967 2 16.0966 3.35349 17.3017 5.42278L15.6574 6.5727C14.7981 5.00361 13.3753 4 11.8333 4C10.1556 4 8.61884 5.18817 7.79563 7.00012L12.3333 7V9L7.23127 9.00053C7.18884 9.32456 7.16666 9.65835 7.16666 10C7.16666 10.342 7.18888 10.6761 7.2314 11.0005L11.3333 11V13L7.79608 13.0009C8.61941 14.8123 10.1559 16 11.8333 16C12.8835 16 13.8784 15.5345 14.6776 14.7419L16.3344 15.9014C15.1484 17.2047 13.5683 18 11.8333 18C9.03573 18 6.6406 15.9321 5.65148 13.0007L3.33333 13V11L5.21835 11.001C5.18424 10.6731 5.16666 10.339 5.16666 10C5.16666 9.66132 5.1842 9.32756 5.21824 8.99995L3.33333 9V7L5.65114 7.00034C6.64005 4.06833 9.03541 2 11.8333 2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </BaseSvg>
)

export default Euro
